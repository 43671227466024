import React from'react';
import { Progress } from 'antd';
import './style.css';

const ProgressBar = (props) => {
  const { percent } = props;
  return(
    <div className="progress-wrapper">
        <Progress percent={percent.toFixed()} />
    </div>
  )
}

export { ProgressBar };