import React, { useState } from 'react';
import { message, Form, Button, Input, Row, Col, Spin } from 'antd';
import axios from 'axios';
const { TextArea } = Input;

function PolygonForm(props) {
  const { addLayer, setPercent, onCancel } = props;
  const [selectedFile, setSelectedFile] = useState(null);
  const authToken = '2bxs3paw11gKuV0i0SwetnHsRr3.ewerewrwekjkwerew';
  const [showUpload, setShowUpload] = useState(true);
  const [loading, setLoading] = useState(false);
  let pollingInterval;

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const checkWorkflowStatus = (wid) => {
    axios.get(`${process.env.REACT_APP_PIXEL_API_URL}query/workflow/${wid}`, {
      headers: {
          'Authorization': `Bearer ${authToken}`,
          'Content-Type': 'application/json'
        }
    })
    .then((res) => {
      if (res.data.status.percent) {
        setPercent(res.data.status.percent);
      }
      if (res.data.status.stage === 'Complete') {
          message.success('Analysis complete')
          clearInterval(pollingInterval);
          const bounds = res.data.status.bounds;
          setLoading(false);
          addLayer({ wid: wid, bounds: bounds, layerType: 'fill' })
      }
      if (res.data.status.stage === 'Error') {
          message.error('Error: An error ocurred while processing the data')
          setLoading(false);
          clearInterval(pollingInterval);
      }
    })
    .catch((err) => {
      console.log(err)
    })
  };

  const handleSubmit = async (values) => {
    //console.log(values)
    let formData;
    let contentType;
    let endpoint;

    onCancel();
  
    if (selectedFile && showUpload) {
      formData = new FormData();
      formData.append('file', selectedFile);
      contentType = 'multipart/form-data';
      endpoint = 'enrich-polygons/file';
    } else {
      formData = values.csv_input;
      contentType = 'text/csv';
      endpoint = 'enrich-polygons/data'
    }
    
    try {
      const response = await axios.post(`${process.env.REACT_APP_PIXEL_API_URL}query/${endpoint}`, formData, {
        headers: {
          'Authorization': `Bearer ${authToken}`,
          'Content-Type': contentType
        },
      });
      message.success('Analyzing...');
      pollingInterval = setInterval(() => checkWorkflowStatus(response.data.wid), 5000);      
    } catch (error) {
      setLoading(false)
      message.error('Error', error);
    }
  };

  return (
    <div>
      <div class="analysis-form-header">
        <h2>Enrich Polygons</h2>
      </div>
      <Row gutter={40}>
     <Col sm={24}>
    <Form 
      layout={'vertical'}
      onFinish={handleSubmit}
      initialValues={{
        csv_input: `
        
        `.replace(/^\s+/gm, '')
     }}
      >
        <p>This tool allows you to enrich polygon data using Pixel.</p>
        <p>You can upload a GeoJSON file.</p>
         
            <div style={{ margin: `20px 0` }}>
             <input type="file" onChange={handleFileChange} />
             </div>
            
          <Button htmlType="submit" type="primary">Submit</Button>
          { loading &&
            <Spin fullscreen={true} />
          }
    </Form>
    </Col>
    </Row>
    </div>
  );
}

export { PolygonForm };
