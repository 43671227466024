import React, { useState } from "react";
import { Menu, Modal } from "antd";
import { useNavigate } from 'react-router-dom';
import logo from "./pixel-logo.png";
import { SignUpForm } from '../SignUpForm';
import "./style.css";

const MainMenu = (props) => {
  const { setLayer } = props;
  const navigate = useNavigate();
  const [current, setCurrent] = useState("worldpop_total");
  const [modalVisible, setModalVisible] = useState(false);
  const [signUpVisible, setSignUpVisible] = useState(false);

  const items = [
    {
      label: (
        <img
          onClick={() => window.location.href = '/'}
          style={{ marginTop: "12px", display: "block" }}
          width={80}
          src={logo}
        />
      ),
      key: "pixel",
    },
    {
      label: "Datasets",
      key: "SubMenu",
      children: [
        
        {
          type: "group",
          label: "Population",
          children: [
            {
              label: <div onClick={() => setLayer("worldpop_total")}>Total - Worldpop</div>,
              key: "worldpop_total",
            },
            /*
            {
              label: <div onClick={() => setLayer("meta_total")}>Total - Meta</div>,
              key: "meta_total",
            },
            */
          ]
        },
            {
              type: 'group',
              label: 'Access',
              children: [
            
                {
                  label: <div onClick={() => setLayer("driving_mean")}>Driving</div>,
                  key: "driving_mean",
                },
                {
                  type: 'group',
                  label: 'Health',
                  children: [
                    
                    {
                      label: <div onClick={() => setLayer("pf_incidence_2020_mean")}>Malaria - Pf Incidence Rate</div>,
                      key: "pf_incidence_2020_mean",
                    },
                    /*
                    {
                      label: <div onClick={() => setLayer("malaria_pf_2020_mean")}>Malaria - Pf Parasite Rate</div>,
                      key: "malaria_pf_2020_mean",
                    }
                    */
                  ]
                },
                {
                  type: "group",
                  label: "Food Security",
                  children: [
                    {
                      label: <div onClick={() => setLayer("ipc")}>Integrated Food Security Phase Classification (IPC)</div>,
                      key: "ipc",
                    },
                  ]
                },
                
        ]}
      ],
    },
    {
      label: <div onClick={() => setModalVisible(true)}>About</div>,
      key: "About",
    },
    {
      label: <div onClick={() => setSignUpVisible(true)}>Sign up</div>,
      key: "sign-up",
    },
  ];

  const onClick = (e) => {
    setCurrent(e.key);
  };

  return (
    <>
      <div className="main-menu">
        <Menu
          onClick={onClick}
          selectedKeys={[current]}
          mode="horizontal"
          items={items}
        />
      </div>
      <SignUpForm 
        open={signUpVisible} 
        onCancel={() => setSignUpVisible(false)}
      />
      <Modal
        open={modalVisible}
        footer={false}
        onCancel={() => setModalVisible(false)}
      >
        <img width={150} src={logo} />
        <p>
        Pixel combines together a wide variety of data sources in a unified global 1KM grid. 
          Import your data into Pixel to enrich, visualize and perform useful geospatial analysis. 
          Then export your data out for further visualization and analysis.
        </p>
        <small>
          <p>
            &copy; 2024{" "}
            <a style={{ color: "#777" }} href="https://ona.io">
              Ona Systems, Inc
            </a>
          </p>
        </small>
      </Modal>
    </>
  );
};

export { MainMenu };