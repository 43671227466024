import React, { useRef, useState, useEffect } from "react";
import Map, { Layer, Source, NavigationControl, Popup } from "react-map-gl";
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import "mapbox-gl/dist/mapbox-gl.css";
import { MainMenu } from "../Menu";
import { ProgressBar } from "../ProgressBar";
import { LevelIndicator } from "../Level";
import axios from 'axios';
import { PlusCircleOutlined, SettingOutlined, TableOutlined, CloudDownloadOutlined } from '@ant-design/icons';
import { PointForm } from "../PointForm";
import { AnalysisForm } from '../AnalysisForm';
import config from '../../config/index.json';
import { useParams, useNavigate } from 'react-router-dom';
import { ConfigProvider, theme, Slider, Table, Tour, Modal, Tabs, Row, Card, Drawer, Button, Col, Space, FloatButton, ColorPicker, Switch } from "antd";
import "./style.css";
import layerStyles from '../../config/layerStyles.json';
import logo from '../Menu/pixel-logo.png';


const draw = new MapboxDraw({
  // Specify drawing options here
});



const MapInstance = (props) => {
  const mapRef = useRef(null);
  const drawRef = useRef(null);
  const params = useParams();
  const navigate = useNavigate();
  const [mapLoaded, setMapLoaded] = useState(false);
  const [showLayerControls, setShowLayerControls] = useState(false);
  const [currentQuadkey, setCurrentQuadkay] = useState();
  const [currentMap, setCurrentMap] = useState();
  const [sources, setSources] = useState([]);
  const [layers, setLayers] = useState([]);
  const [showProgressBar, setShowProgressBar] = useState(false);
  const [pop, setPop] = useState(0);
  const [qk, setQk] = useState("");
  const [total, setTotal] = useState(0);
  const [men, setMen] = useState(0);
  const [women, setWomen] = useState(0);
  const [youth, setYouth] = useState(0);
  const [child, setChild] = useState(0);
  const [drawing, setDrawing] = useState(false);
  const [children, setChildren] = useState(0);
  const [elderly, setElderly] = useState(0);
  const [progressPercent, setProgressPercent] = useState(0);
  const [loading, setLoading] = useState(false);
  const [activeLayer, setActiveLayer] = useState("worldpop_total");
  const [layerOpacity, setLayerOpacity] = useState(0.9);
  const [startColor, setStartColor] = useState('#021520');
  const [endColor, setEndColor] = useState('#1FADFF');
  const [showSatellite, setShowSatellite] = useState(false);
  const [currentWid, setCurrentWid] = useState();
  const [access, setAccess] = useState();
  const [showFeatureProperties, setShowFeatureProperties] = useState(false);
  const [showExtrusions, setShowExtrusions] = useState(false);
  const [showActions, setShowActions] = useState(false);
  const [layerName, setLayerName] = useState();
  const [layerCredit, setLayerCredit] = useState();
  const [layerSourceUrl, setLayerSourceUrl] = useState();
  const [popupInfo, setPopupInfo] = useState(null);
  const [currentZoom, setCurrentZoom] = useState();
  const [showDrawer, setShowDrawer] = useState(false);
  const [dataTableColumns, setDataTableColumns] = useState();
  const [dataTableRows, setDataTableRows] = useState([]);
  const [showCentroids, setShowCentroids] = useState(false);
  const [populationLayer, setPopulationLayer] = useState('worldpop_total');
  const authToken = '2bxs3paw11gKuV0i0SwetnHsRr3.ewerewrwekjkwerew';
  const [open, setOpen] = useState(false);
  const ref1 = useRef(null);

  useEffect(() => {
   setLayerName(layerStyles[activeLayer].name);
   setLayerCredit(layerStyles[activeLayer].source);
   setLayerSourceUrl(layerStyles[activeLayer].sourceUrl);
  }, [activeLayer])

  useEffect(() => {
    if (mapRef.current && params.id) {
      addLayer({
        wid: params.id,
        layerType: params.type
      })
    }
  }, [params.id, mapLoaded])
  
  useEffect(() => {
    if (layers?.length > 0) {
      const wid = params.id || currentWid; 
      axios.get(`${process.env.REACT_APP_PIXEL_API_URL}query/geojson/${wid}`, {
        headers: {
            'Authorization': `Bearer ${authToken}`,
            'Content-Type': 'application/json'
          }
      }).then((res) => {
        const columns = [];
        Object.keys(res.data[0].properties)
        .forEach((key) => columns.push({ key: key, dataIndex: key, title: key }));
        const rows = [];
        res.data.forEach((item) => rows.push(item?.properties));
        setDataTableColumns(columns);
        setDataTableRows(rows);
      })
    }
  }, [showDrawer])

  useEffect(() => {
    if (mapRef.current) {
    const map = mapRef.current.getMap();

    const handleMouseMove = (e) => {
      const features = map.queryRenderedFeatures(e.point);
      if (features[0]?.properties[populationLayer]) {
        //console.log(features[0])
        return setPopupInfo({
          feature: features[0],
          lng: e.lngLat.lng,
          lat: e.lngLat.lat
        })
      }
    };
      map.on('mousedown', handleMouseMove);
    return () => {
      map.off('mouseout', handleMouseMove);
    };
}
}, [mapLoaded]);


  

  // Initialize MapboxDraw
  useEffect(() => {
    drawRef.current = new MapboxDraw({
      displayControlsDefault: false,
      controls: {
        polygon: true,
        marker: false,
        trash: true,
      },
    });
    return () => {
      if (mapRef.current) {
        const map = mapRef.current.getMap();
        map.removeControl(drawRef.current);
      }
    };//
  }, []);

  // Add MapboxDraw control to the map
  useEffect(() => {
    if (mapRef.current) {
      const map = mapRef.current.getMap();
      map.addControl(
        new MapboxDraw({
          displayControlsDefault: false,
          controls: {
            polygon: true,
            trash: true,
          },
        }),
        "top-right",
      );
    }
  }, [mapLoaded]);

  useEffect(() => {
    if (mapRef.current) {
      const map = mapRef.current.getMap();
      map.on("draw.modechange", (e) => { console.log(e); 
        if (e.mode === 'simple_select') {
          setDrawing(false) 
        } else {
          setDrawing(true)
        }
      });
      map.on("draw.create", (e) => handlePopSum(e));
      map.on("draw.update", (e) => handlePopSum(e));
    }
  }, [mapLoaded]);

  const handlePopSum = (event) => {
    const polygon = event.features[0];
    const map = mapRef.current.getMap();
    // Query for features under the polygon
    const features = map.queryRenderedFeatures({
      filter: ["within", polygon],
    });
    const obj = {};
    features.forEach((feature) => obj[feature.properties.quadkey] = feature);
    const array = [];
    Object.keys(obj).forEach((key) => array.push(obj[key]))

    let t = 0;
    let m = 0;
    let w = 0;
    let y = 0;
    let c = 0;
    let e = 0;
    let i = 0;

    const filterAccessMeanValues = (features, value) => {
      const filteredValues = features.reduce((acc, feature) => {
        const accessMean = feature.properties.access_mean;
        // Ensure access_mean exists, is a number, and is less than or equal to 1 before adding to the accumulator
        if (typeof accessMean === 'number' && accessMean.toFixed() === value) {
          if (feature.properties[populationLayer] !== undefined) {
            acc.push(feature.properties[populationLayer]);
          }
        }
        return acc;
      }, []);
    
      return filteredValues;
    }

    const sumArray = (array) => array.reduce((accumulator, currentValue) => {
      return accumulator + currentValue;
    }, 0); 
    
    const result1 = filterAccessMeanValues(array, '1');
    const result2 = filterAccessMeanValues(array, '2');
    const result3 = filterAccessMeanValues(array, '3');
    const result4 = filterAccessMeanValues(array, '4');
    const result5 = filterAccessMeanValues(array, '5');
    const accessGroups = { 1: sumArray(result1), 2: sumArray(result2), 3: sumArray(result3), 4: sumArray(result4), 5: sumArray(result5)};
    setAccess(accessGroups);

    if (populationLayer === 'meta_total') {
    array.forEach((f) => {
      if (!isNaN(f.properties.meta_total)) {
        t = t + f.properties.meta_total;
      }
      if (!isNaN(f.properties.meta_men)) {
        m = m + f.properties.meta_men;
      }
      if (!isNaN(f.properties.meta_women)) {
        w = w + f.properties.meta_women;
      }
      if (!isNaN(f.properties.meta_youth)) {
        y = y + f.properties.meta_youth;
      }
      if (!isNaN(f.properties.meta_women_childbearing)) {
        c = c + f.properties.meta_women_childbearing;
      }
      if (!isNaN(f.properties.meta_elderly)) {
        e = e + f.properties.meta_elderly;
      }
      if (!isNaN(f.properties.meta_children)) {
        i = i + f.properties.meta_children;
      }
    });
    
    setTotal(t);
    setMen(m);
    setWomen(w);
    setChild(c);
    setYouth(y);
    setElderly(e);
    setChildren(i);
  }

  if (populationLayer === 'worldpop_total') {
    array.forEach((f) => {
      if (!isNaN(f.properties.worldpop_total)) {
        t = t + f.properties.worldpop_total;
      }
    })
    setTotal(t);
  }
  };


  const setLayer = (layer) => {
    setActiveLayer(layer);
  };

  const isVisible = (layer) => {
    if (layer === activeLayer) {
      return "visible";
    }
    return "none";
  };

  const numberWithCommas = (x) => {
    x = x.toFixed();
    var pattern = /(-?\d+)(\d{3})/;
    while (pattern.test(x)) x = x.replace(pattern, "$1,$2");
    return x;
  };

  const intlFormat = (num) => {
    return new Intl.NumberFormat().format(Math.round(num * 10) / 10);
  };

  const makeFriendly = (num) => {
    if (num >= 1000000) return intlFormat(num / 1000000) + "M";
    if (num >= 1000) return intlFormat(num / 1000) + "k";
    return intlFormat(num);
  };

  const getLayerStyle = (level) => {
    const layerStyle = layerStyles[activeLayer];
    if (layerStyle) {
      if (layerStyle.method === 'interpolate') {
        return [
          "interpolate",
          ["linear"],
          ["get", activeLayer],
          0,
          layerStyle?.startColor,
          getMaxValue(level, activeLayer),
          layerStyle?.endColor,
        ]
      }
      if (layerStyle.method === 'steps') {
        return layerStyle['fill-color'];
      }
    }
  }

  const getRadiusStyle = (level) => {
    return [
      "interpolate",
      ["linear"],
      ["get", "worldpop_total"],
      0,
      2,
      getMaxValue(level, "worldpop_total"),
      4,
    ]
  }

  const getMinZoom = (level) => {
    if (level === 16) {
      return 11
    }
    if (level === 15) {
      return 10
    }
    if (level === 14) {
      return 9
    }
    if (level === 13) {
      return 8
    }
    if (level === 12) {
      return 5
    }
    if (level === 11) {
      return 3
    }
    if (level === 10) {
      return 2
    }
    if (level === 9) {
      return 0
    }
  }

  const getMaxZoom = (level) => {
    if (level === 16) {
      return 13
    }
    if (level === 15) {
      return 11
    }
    if (level === 14) {
      return 10
    }
    if (level === 13) {
      return 9
    }
    if (level === 12) {
      return 8
    }
    if (level === 11) {
      return 5
    }
    if (level === 10) {
      return 4
    }
    if (level === 9) {
      return 3
    }
  }

  const getMaxValue = (level, layer) => {
    if (layer === 'meta_total' || layer === 'worldpop_total') {
    if (level === 16) {
      return 1000
    }
    if (level === 15) {
      return 3858
    }
    if (level === 14) {
      return 12377
    }
    if (level === 13) {
      return 28677
    }
    if (level === 12) {
      return 103749
    }
    if (level === 11) {
      return 373639
    }
    if (level === 10) {
      return 1136990
    }
    if (level === 9) {
      return 2666228
    }
  }
  if (layer === 'driving_mean') {
    return 30
  }
  if (layer === 'malaria_pf_2020_mean') {
    return 0.6
  }
  if (layer === 'pf_incidence_2020_mean') {
    return 0.6
  }
  if (layer === 'ipc') {
    return 0.6
  }
  }


  const addLayer = (data) => {
      const map = mapRef.current.getMap();

      const currentSources = sources;
      currentSources.push({
        id: `source-${data.wid}`,
        type: 'vector',
        tiles: [`https://d1dzia0kg2ebo9.cloudfront.net/${data.wid}/{z}/{x}/{y}.mvt`],
        minzoom: 0,
        maxzoom: 9
      })
      setSources(currentSources);

      const currentLayers = layers;
      if (data.layerType === 'circle') {
        currentLayers.push({
          id: `${data.wid}`,
          type: "circle",
          minzoom: 0,
          source: `source-${data.wid}`,
          "source-layer": `${data.wid}`,
          layout: {},
          paint: {
            "circle-color": "#87FFBC",
            "circle-opacity": 0.3,
            "circle-stroke-width": 1,
            "circle-stroke-opacity": 1,
            "circle-stroke-color": "#87FFBC"
          }
        })
      }
      if (data.layerType === 'buffer') {
        currentLayers.push({
          id: `${data.wid}`,
          type: "fill",
          minzoom: 0,
          source: `source-${data.wid}`,
          "source-layer": `${data.wid}`,
          layout: {},
          paint: {
            "fill-color": "#87FFBC",
            "fill-opacity": 0.2,
            "fill-outline-color": "#87FFBC"
          }
        })
        currentLayers.push({
          id: `${data.wid}-buffer-line`,
          type: "line",
          minzoom: 0,
          source: `source-${data.wid}`,
          "source-layer": `${data.wid}`,
          layout: {},
          paint: {
            "line-color": "#87FFBC",
            "line-opacity": 1,
            "line-width": 1
          }
        })
      }
      if (data.layerType === 'fill') {
        currentLayers.push({
          id: `${data.wid}`,
          type: "fill",
          minzoom: 0,
          source: `source-${data.wid}`,
          "source-layer": `${data.wid}`,
          layout: {},
          paint: {
            "fill-color": "#87FFBC",
            "fill-opacity": 0.2
          }
        })
        currentLayers.push({
          id: `${data.wid}-line`,
          type: "line",
          minzoom: 0,
          source: `source-${data.wid}`,
          "source-layer": `${data.wid}`,
          layout: {},
          paint: {
            "line-color": "#87FFBC",
            "line-opacity": 1,
            "line-width": 1
          }
        })
      }
      setLayerOpacity(0.2);
      setLayers(currentLayers);
      if (data.bounds) {
        map.fitBounds(data?.bounds, {
          padding: {top: 200, bottom: 200, left: 200, right: 200}
        })
      }

  }


  /****************************************************************
  * LAYERS
  *****************************************************************/
  
  const buildings = {
    id: "buildingsLayer",
    type: "fill",
    minzoom: 13,
    source: "buildings",
    "source-layer": "building_footprints",
    layout: {},
    paint: {
      "fill-color": endColor,
      "fill-opacity": 0.5,
      "fill-outline-color": endColor
    }
  };

  const labelLayer = {
    id: 'label-layer',
    type: 'symbol',
    minzoom: 13,
    source: 'centroid-NGA-15',
    "source-layer": "geojson",
    layout: {
      'text-field': ['get', activeLayer],
      "text-size": [
        "interpolate",
        ["linear"],
        ["zoom"],
        13, 11, 
        14, 15,
        15, 19,
        16, 23,
        17, 27
      ]
    },
    paint: {
      'text-color': '#fff',
      'text-halo-width': 1,
      'text-halo-color': '#111',
      'text-opacity': layerOpacity,
      "text-translate": [
        "interpolate",
        ["linear"],
        ["zoom"],
        13, [35, 45], 
        14, [90, 110],
        15, [200, 220],
        16, [380, 440],
        17, [740, 880]
      ]
    }
 }

 const markerLayer = {
  id: 'marker-layer',
  type: 'circle',
  minzoom: 13,
  maxzoom: 15,
  source: 'centroid-NGA-15',
  "source-layer": "geojson",
  filter: ["all", ["has", activeLayer]],
  paint: {
    'circle-stroke-color': '#fff',
    'circle-stroke-width': 1,
    'circle-color': [
      "interpolate",
      ["linear"],
      ["get", activeLayer],
      0,
      startColor,
      getMaxValue(15, activeLayer),
      endColor,
    ],
    "circle-radius": [
      "interpolate",
      ["linear"],
      ["zoom"],
      13, 5,
      14, 10,
      15, 20,
      16, 22,
      17, 28
    ],
    "circle-translate": [
      "interpolate",
      ["linear"],
      ["zoom"],
      13, [-50, 50], 
      14, [-100, 100],
      15, [-205, 205],
      16, [-330, 330],
      17, [-400, 400]
    ]
  }
}



  return (
    <ConfigProvider
      theme={{
        token: {
         
          
          colorPrimary: "#0781C9",
          fontSize: 14,
        },
        components: {
          "Menu": {
           // "itemActiveBg": "rgb(51, 61, 69)",
            
           // "itemSelectedBg": "rgb(23, 24, 25)",
            "itemDisabledColor": '#999',
            "groupTitleColor": "#777"
          }
        }
      }}
    >
      <MainMenu setLayer={setLayer} />
      <Map
        mapboxAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
        ref={mapRef}
        mapLib={import("mapbox-gl")}
        projection="globe"
        initialViewState={{
          longitude: 20.6751,
          latitude: 5.7679,
          zoom: 2.5,
        }}
        style={{ width: "100hw", height: "100vh", outline: "none" }}
        onZoom={(e) => {
          setCurrentZoom(e.viewState.zoom)
        }}
        mapStyle={ 
          showSatellite ? `mapbox://styles/ona/clw7v7vrg09sb01ph8mbogzrv`
                        : `mapbox://styles/ona/clw7vqrhz00em01qgegf71vuc`
        }   
        // `mapbox://styles/mapbox/dark-v8`
        onLoad={(e) => {
          setMapLoaded(true);
          const map = mapRef.current.getMap();
          setCurrentMap(map);
          /*
          setTimeout(() => {
            map.flyTo({
              center: { lon: 7.491302, lat: 9.072264 },
              zoom: 6,
            });
          }, 1000);
          */
        }}
      >
        

        <Source
          id="buildings"
          type="vector"
          tiles={[
            "https://d1dzia0kg2ebo9.cloudfront.net/buildings/buildings/{z}/{x}/{y}.mvt",
          ]}
          minzoom={0}
          maxzoom={15}
        />

      
        { Object.keys(config).map((country) => (
          config[country].levels.map((level) => (
           <Source 
           id={`grid-${country}-${level}`}
           type="vector"
           tiles={[
             `https://d1dzia0kg2ebo9.cloudfront.net/pixel-grid/${country}/pmtiles/${level}/{z}/{x}/{y}.mvt`,
           ]}
           minzoom={0}
           maxzoom={9}
            />
           ))
        ))}

          { Object.keys(config).map((country) => (
          config[country].levels.map((level) => (
           <Source 
           id={`centroid-${country}-${level}`}
           type="vector"
           tiles={[
             `https://d1dzia0kg2ebo9.cloudfront.net/pixel-grid/${country}/pmtiles/centroid-${level}/{z}/{x}/{y}.mvt`,
           ]}
           minzoom={0}
           maxzoom={9}
            />
           ))
        ))}

        { !showCentroids && Object.keys(config).map((country) => (
          config[country].levels.map((level) => (
           <Layer 
           id={`layer-keys-${country}-${level}`}
           source={`grid-${country}-${level}`}
           type="fill"
           minzoom={getMinZoom(level)}
           maxzoom={getMaxZoom(level)}
           layout={{
             visibility: isVisible(activeLayer)
           }}
           filter={['all', ['>', activeLayer, 0]]}
           beforeId={"road-primary"}
           source-layer={`geojson`}
           paint={{
            "fill-color": getLayerStyle(level),
            "fill-opacity": layerOpacity,
            "fill-outline-color": "#fff"
           }}
          />
          ))
        ))}


          { showCentroids && Object.keys(config).map((country) => (
          config[country].levels.map((level) => (
           <Layer 
           id={`layer-circles-${country}-${level}`}
           source={`centroid-${country}-${level}`}
           type="circle"
           minzoom={getMinZoom(level)}
           maxzoom={getMaxZoom(level)}
           layout={{
             visibility: isVisible(activeLayer)
           }}
           beforeId={"road-primary"}
           source-layer={`geojson`}
           filter={["all", ["has", activeLayer]]}
           paint={{
            "circle-color": getLayerStyle(level),
            "circle-opacity": layerOpacity,
            "circle-radius": getRadiusStyle(level)
           }}
          />
          ))
        ))}


          { Object.keys(config).map((country) => (
          config[country].levels.map((level) => (
           <Layer 
           id={`layer-centroid-${country}-${level}`}
           source={`centroid-${country}-${level}`}
           type="circle"
           minzoom={getMinZoom(level)}
           maxzoom={getMaxZoom(level)}
           beforeId={"waterway-label"}
           source-layer={`geojson`}
           paint={{
             "circle-color": '#fff',
             "circle-radius": 2,
             "circle-opacity": 0
            }}
            />
           ))
        ))}



         { Object.keys(config).map((country) => (
           <Layer 
           id={`layer-grid-${country}-15`}
           source={`grid-${country}-15`}
           type="line"
           minzoom={13}
           beforeId={"waterway-label"}
           source-layer={`geojson`}
           paint={{
             "line-color": '#fff',
             "line-width": 2,
             "line-opacity": layerOpacity
            }}
            />
        ))}

        <Layer {...buildings} />
        <Layer {...markerLayer} />
        <Layer {...labelLayer} />

          
        { sources.map((source) => (
          <Source {...source} />
        ))}

        { layers.map((layer) => (
          <Layer {...layer} />
        ))}
    
        <NavigationControl />
      
        <FloatButton.Group shape="square" style={{ right: 15 }}>
        { !showProgressBar &&
        <FloatButton 
          tooltip={'Analysis'}
          ref={ref1}
          icon={<PlusCircleOutlined />} 
          onClick={() => setShowActions(true)}
        />
        }
        { layers?.length > 0 &&
        <FloatButton
          tooltip={'Table'}
          icon={<TableOutlined />}
          onClick={() => setShowDrawer(showDrawer ? false : true )}
        />
        }
         <FloatButton 
           tooltip={'Settings'}
           icon={<SettingOutlined />} 
           onClick={() => setShowLayerControls(showLayerControls ? false : true )}
         />
        </FloatButton.Group>

        {popupInfo && !drawing &&
        <Popup
        longitude={popupInfo.lng}
        latitude={popupInfo.lat}
        closeButton={true}
        closeOnClick={false}
        onClose={() => {
          setPopupInfo(null) 
          setShowFeatureProperties(false)
        }}
        >
         <div><img width={70} src={logo} /></div>
         { popupInfo.feature.properties[populationLayer] &&
          <div>
          <b>Population</b><br/>
          { numberWithCommas(popupInfo.feature.properties[populationLayer])}
          </div>
         }
         <div style={{ textAlign: 'right'}}>
         <a href="#show-props"
           onClick={(e) => {
            e.preventDefault();
             showFeatureProperties ? setShowFeatureProperties(false) : setShowFeatureProperties(true)
           }}>
            { showFeatureProperties ? <>[-]</> : <>[+]</> }
            </a>
          </div>
         { showFeatureProperties &&
         <table>
         { Object.keys(popupInfo.feature.properties).map((key) => (
          <tr>
          <td>{key}</td>
          <td style={{ fontWeight: 'bold'}}>{popupInfo.feature.properties[key]}</td>
          </tr>
         ))}
         </table>
         }
        </Popup>
        }

        <Modal
          title="Data"
          mask={false} 
          width={800}
          open={showDrawer}
          footer={false}
          onCancel={() => { setShowDrawer(false)}}
        >
          <div style={{ marginTop: 20, marginBottom: 20 }}>
          <Button 
            type="primary"
            onClick={() => {
              const wid = params.id || currentWid;
              if (wid) {
                const url = `${process.env.REACT_APP_PIXEL_API_URL}query/download-geojson/${wid}`;
                window.open(url, '_blank');
             }
            }}
          >
            <CloudDownloadOutlined /> Download GeoJSON
          </Button>
          </div>
          <div style={{
            overflowY: "scroll"
          }}>
          <Table 
           columns={dataTableColumns}
           dataSource={dataTableRows}
           pagination={false}
           size={'small'}
          />
          </div>
          <small><p>Only the first 100 rows are shown.</p></small>
        </Modal>

        <Modal
         open={showActions}
         onCancel={() => setShowActions(false)}
         destroyOnClose={true}
         mask={false}
         footer={false}
         width={600}
        >
          <img width={120} src={logo} />
              <AnalysisForm 
                onCancel={() => {
                  setShowActions(false);
                  setShowProgressBar(true);
                }}
                setPercent={(percent) => {
                  setProgressPercent(percent);
                  if (percent === 100) {
                    setTimeout(() => {
                      setShowProgressBar(false);
                    }, 2000)
                  }
                }}
                addLayer={(data) => {
                  addLayer(data)
                  setCurrentWid(data.wid);
                  window.history.pushState(null, '', `/analysis/${data.layerType}/${data.wid}`);
                }}
              />   
        </Modal>
        { showProgressBar &&
          <ProgressBar percent={progressPercent} />
        }

        { currentMap &&
        <LevelIndicator zoom={currentZoom} />
        }
      </Map>

      {total && (
        <div className="sidebar">
          <Card>
            <label>Total population</label>
            <div className="large">{makeFriendly(total)}</div>
            <div className="small">{numberWithCommas(total)}</div>
            <hr />
            { populationLayer === 'meta_total' &&
            <div>
              Women: <b>{numberWithCommas(women)}</b>
              <br />
              Men: <b>{numberWithCommas(men)}</b>
              <br />
              Children: <b>{numberWithCommas(children)}</b>
              <br />
              Youth: <b>{numberWithCommas(youth)}</b>
              <br />
              Elderly: <b>{numberWithCommas(elderly)}</b>
              <br />
              { child > 0 &&
              <p>
                <b>{numberWithCommas(child)}</b> women of childbearing age
              </p>
              }
            </div>
            }
            {/*}
            { access &&
            <div>
             <b>Access mean</b><br/>
             1: <b>{numberWithCommas(access[1])}</b><br/>
             2: <b>{numberWithCommas(access[2])}</b><br/>
             3: <b>{numberWithCommas(access[3])}</b><br/>
             4: <b>{numberWithCommas(access[4])}</b><br/>
             5: <b>{numberWithCommas(access[5])}</b><br/>
            </div>
            }
          {*/}
          </Card>
        </div>
      )}

      { layerName &&
       <div className="layer-name">
        <h2>{ layerName }</h2> 
        <a target="blank" href={layerSourceUrl}>{ layerCredit }</a>
        </div>
      }
     
      { showLayerControls &&
       <div className="layer-controls">
         <Card
           title="Settings"
         >
          <div className="item">
            <label>Opacity</label>
          <Slider 
           value={layerOpacity}
           min={0.1}
           max={1}
           step={0.1}
           onChange={(value) => setLayerOpacity(value)}
          />
          </div>
          {/*}
          <div className="item">
            <label>Color</label>
            <ColorPicker 
               value={startColor}
               defaultValue={startColor}
               size="small"
               disabledAlpha={true}
               onChangeComplete={(value) => {
                setStartColor(`rgba(${value.metaColor.r},${value.metaColor.g},${value?.metaColor.b}, 1)`)
               }}
            />
          </div> 
          <div className="item">
            <label>Color</label>
            <ColorPicker 
               value={endColor}
               defaultValue={endColor}
               size="small"
               disabledAlpha={true}
               onChangeComplete={(value) => {
                setEndColor(`rgba(${value.metaColor.r},${value.metaColor.g},${value?.metaColor.b}, 1)`)
               }}
            />
          </div> 
              {*/}
          <div className="item">
              <label>Labels</label>
              <Switch 
                value={showSatellite}
                onChange={() => setShowSatellite(showSatellite ? false : true)}
              />
          </div>
          <div className="item">
              <label>Centroids</label>
              <Switch 
                value={showCentroids}
                onChange={() => setShowCentroids(showCentroids ? false : true)}
              />
          </div>
          {/*}
          { activeLayer === 'meta_total' &&
          <div className="item">
              <label>Extrusions</label>
              <Switch 
                value={showExtrusions}
                onChange={() => setShowExtrusions(showExtrusions ? false : true)}
              />
          </div>
          }
        {*/}
          </Card>
        </div>
        }

    </ConfigProvider>
  );
};

export { MapInstance };
