import React, { useEffect } from 'react';
import { Button, Form, Input, Modal } from 'antd';
import './style.css';


const SignUpForm = (props) => {
    const { open, onCancel } = props;
    
    useEffect(() => {
        const script = document.createElement('script');
        script.src='https://js.hsforms.net/forms/v2.js';
        document.body.appendChild(script);

        script.addEventListener('load', () => {
            // @TS-ignore
            if (window.hbspt) {
                // @TS-ignore
                window.hbspt.forms.create({
                    region: "na1",
                    portalId: "44716315",
                    formId: "3adb5642-c10c-4ec8-b36d-71b609746d51",
                    target: `#hubspotForm`
                })
            }
        });
    }, [open]);

  return (
    <Modal 
      open={open}
      onCancel={() => onCancel(false)}
      footer={false}
    >
    <h2>No spam. Just news on Pixel.</h2>
    <p>Sign up with your email below.</p>
    <div className="hubspot-form" id={`hubspotForm`}></div>
    </Modal>
  )
}

export { SignUpForm };



