import React, { useState } from 'react';
import { message, Form, Button, Input, Switch, Checkbox, Row, Select, Col, Spin } from 'antd';
import axios from 'axios';
const { TextArea } = Input;
const { Option } = Select;

function BufferForm(props) {
  const { addLayer, setPercent, onCancel } = props;
  const [selectedFile, setSelectedFile] = useState(null);
  const authToken = '2bxs3paw11gKuV0i0SwetnHsRr3.ewerewrwekjkwerew';
  const [showUpload, setShowUpload] = useState(false);
  const [loading, setLoading] = useState(false);
  let pollingInterval;

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const checkWorkflowStatus = (wid) => {
    axios.get(`${process.env.REACT_APP_PIXEL_API_URL}query/workflow/${wid}`, {
      headers: {
          'Authorization': `Bearer ${authToken}`,
          'Content-Type': 'application/json'
        }
    })
    .then((res) => {
      if (res.data.status.percent) {
        setPercent(res.data.status.percent);
      }
      if (res.data.status.stage === 'Complete') {
          message.success('Analysis complete')
          clearInterval(pollingInterval);
          const bounds = res.data.status.bounds;
          setLoading(false);
          addLayer({ wid: wid, bounds: bounds, layerType: 'buffer' })
      }
      if (res.data.status.stage === 'Error') {
          message.error('Error: An error ocurred while processing the data')
          setLoading(false);
          clearInterval(pollingInterval);
      }
    })
    .catch((err) => {
      console.log(err)
    })
  };

  const handleSubmit = async (values) => {

    let formData;
    let contentType;
    let endpoint;
 
    if (selectedFile && showUpload) {
      formData = new FormData();
      formData.append('file', selectedFile);
      contentType = 'multipart/form-data';
      endpoint = `buffer-analysis/file/${values.buffer_radius}/${values.clip_buffers}`;
    } else {
      formData = values.csv_input;
      contentType = 'text/csv';
      endpoint = `buffer-analysis/data/${values.buffer_radius}/${values.clip_buffers}`
    }
    try {
      const response = await axios.post(`${process.env.REACT_APP_PIXEL_API_URL}query/${endpoint}`, formData, {
        headers: {
          'Authorization': `Bearer ${authToken}`,
          'Content-Type': contentType
        },
      });
      message.success('Analyzing...');
      onCancel();
      pollingInterval = setInterval(() => checkWorkflowStatus(response.data.wid), 2000);      
    } catch (error) {
      setLoading(false);
      message.error('Error', error);
    }
  };

  return (
    <div>
      <div class="analysis-form-header">
        <h2>Buffer Analysis</h2>
      </div>
      <Row gutter={40}>
     <Col sm={24}>
    <Form 
      layout={'vertical'}
      onFinish={handleSubmit}
      initialValues={{
        buffer_radius: 10,
        clip_buffers: true,
        csv_input: `
        latitude,longitude,statename,category,prmry_name
        11.82232,13.14832,Borno,Primary Health Center,G R A Nursing Home
        11.82161458,13.14717611,Borno,Primary Health Center,Gishili Health Center
        10.70709229,12.81628418,Borno,Primary Health Center,Lehobi Primary Health Care
        10.61522,12.18162833,Borno,Primary Health Center,Dugja Idp Camp
        10.75952667,13.31335833,Borno,Dispensary,Kopa Maikudiri Dispensary
        11.7980957,13.14227295,Borno,Medical Center,Ghumtukum Medical Center
        11.2254,13.78631,Borno,Dispensary,Ghung Dispensary
        10.586574,12.050219,Borno,Dispensary,Grem Demch Dispensary
        11.051521,12.807163,Borno,Federal Medical Center,Gumsuri Federal Medical Center
        10.458997,13.213818,Borno,Dispensary,Uba Bobo Health Post
        13.67903,13.34196,Borno,Primary Health Center,Kangarwa Health Clinic
        10.543952,11.62133,Borno,Dispensary,Anguwan Banki Health Post
        10.066969,11.999712,Borno,Dispensary,Jeki Kanakuri Dispensary
        10.43496,12.10309,Borno,Dispensary,Gwangang Chata Dispensary
        10.934143,12.526363,Borno,Dispensary,Wajiroko Dispensary
        10.632343,12.545808,Borno,Primary Health Center,Ngulde Primary Health Care
        13.193403,12.570565,Borno,Primary Health Center,Meleri Primary Health Care
        13.3015,12.70951,Borno,Dispensary,Asaga Dispensary
        13.32059,12.73928,Borno,Primary Health Center,Huwa Health Clinic
        10.80278,12.7768,Borno,Dispensary,Shikarkir Dispensary
        10.83184,12.79652,Borno,Dispensary,Bambila Health Post
        11.273074,12.869774,Borno,Dispensary,Yerwa Dispensary
        11.08396,12.89625,Borno,Dispensary,Koljiri Dispensary
        10.88869,12.90377,Borno,Dispensary,Mifa Health Post
        11.38765,12.90746,Borno,Primary Health Center,Bulublin Health Clinic
        11.13765,12.95213,Borno,Dispensary,Njaba Dispensary
        10.85874,12.96112,Borno,Dispensary,Kaumutayahi Health Post
        12.249895,13.10427,Borno,Dispensary,Gajigana Dispensary
        12.90304,13.22929,Borno,Primary Health Center,Bulturam Health Center
        12.32442,13.23876,Borno,Primary Health Center,Mawa Lawanti Primary Health Center
        11.827838,13.274358,Borno,Dispensary,Tamsuwu Ngamdua Dispensary
        11.88444,13.34538,Borno,Primary Health Center,Ngom Clinic
        12.68522,13.35501,Borno,Dispensary,Goni Adamt Dispensary
        13.48373,13.48475,Borno,Dispensary,Tumbun Guni Health Post
        12.812065,13.670874,Borno,Primary Health Center,Barwati Health Clinic
        11.63561,13.67786,Borno,Primary Health Center,Mal Mastari Health Center
        12.89494,13.69314,Borno,Primary Health Center,Bumou Health Clinic
        11.972137,13.707864,Borno,Dispensary,Ajiri Dispensary
        12.52341,13.77079,Borno,Primary Health Center,Ngelaiwa Health Clinic
        11.12896,13.7922,Borno,Dispensary,Hudumcha Dispensary
        11.43373,13.79368,Borno,Primary Health Center,Buluugu Health Center
        `.replace(/^\s+/gm, '')
     }}
      >
        <p>This tool allows you to do buffer analysis. Ensure your data contains <b>latitude</b> and <b>longitude</b> columns.</p>
        <p>You can add CSV data directly in the <a href="#csv" onClick={(e) => { e.preventDefault(); setShowUpload(false)}}>input</a> below or <a href="#upload" onClick={(e) => { e.preventDefault(); setShowUpload(true)}}>upload</a> a file.</p>
           <Form.Item
              name="buffer_radius"
              label="Radius"
            >
                <Select>
                  <Option value={1}>1km</Option>
                  <Option value={2}>2km</Option>
                  <Option value={3}>3km</Option>
                  <Option value={4}>4km</Option>
                  <Option value={5}>5km</Option>
                  <Option value={10}>10km</Option>
                  <Option value={15}>15km</Option>
                  <Option value={20}>20km</Option>
                  <Option value={25}>25km</Option>
                  <Option value={30}>30km</Option>
                  <Option value={35}>35km</Option>
                  <Option value={40}>40km</Option>
                  <Option value={45}>45km</Option>
                  <Option value={50}>50km</Option>
                </Select>
            </Form.Item>
            <Form.Item
              name="clip_buffers"
              label="Avoid overlaps by clipping buffers"
            >
              <Switch valuePropName="checked" />
            </Form.Item>
            {!showUpload &&
            <Form.Item
              name="csv_input"
              label="CSV"
            >
                <TextArea style={{ background: '#f9f9f9', color: '#777' }} rows={20} />
            </Form.Item>
            
            }
            {showUpload &&
            <div style={{ margin: `20px 0` }}>
             <input type="file" onChange={handleFileChange} />
             </div>
            }
            
          <Button htmlType="submit" type="primary">Submit</Button>
          { loading &&
            <Spin fullscreen={true} />
          }
    </Form>
    </Col>
    </Row>
    </div>
  );
}

export { BufferForm };
