import React, { useState } from 'react';
import { message, Form, Button, Row, Col, Input, Spin } from 'antd';
import './style.css';
import { PointForm } from '../PointForm';
import { PolygonForm } from '../PolygonForm';
import { BufferForm } from '../BufferForm';
import Thumb from './analysis-thumb.png';
import PointsThumb from './points-thumb.png';
import PolygonThumb from './polygon-thumb.png';
import BufferThumb from './buffer-thumb.png';

const analysisItems = [{
  title: "Point Enrichment",
  text: "Enrich your point data by adding external data attributes, such as malaria rates, to specific locations. Ideal for enhancing your dataset with valuable insights.",
  button: "Enrich Points",
  flow: "enrichPoints",
  thumb: PointsThumb,
  disabled: false
},{
  title: "Polygon Enrichment",
  text: "Calculate and visualize demographic or environmental data within your polygons. This flow allows you to upload GeoJSON files and enrich them with data.",
  button: "Enrich Polygons",
  flow: "enrichPolygons",
  thumb: PolygonThumb,
  disabled: false
},{
  title: "Buffer Analysis",
  text: "Create buffers around your points to analyze spatial relationships and impacts. Specify your desired radius to see how many people or features fall within these areas.",
  button: "Buffer Analysis ",
  flow: "analyzeBuffers",
  thumb: BufferThumb,
  disabled: false
}/*,{
  title: "Voronoi Diagram",
  text: "Generate Voronoi diagrams to partition space based on the distribution of your point data. This advanced spatial analysis tool helps in identifying areas of influence for each point.",
  button: "Create Voronoi Diagram",
  disabled: true
},{
  title: "Clipped Voronoi",
  text: "Refine your buffer analysis by clipping buffers with Voronoi cells for precise spatial inquiry. This flow combines the power of buffer and Voronoi analyses.",
  button: "Create Clipped Voronoi",
  disabled: true
}*/];

function AnalysisForm(props) {
  const [showAnalysisOptions, setShowAnalysisOptions] = useState(true);
  const [showFlow, setShowFlow] = useState();
  const { addLayer, setPercent, onCancel } = props;
  
  return (
    <div>
    { showAnalysisOptions && (
    <div className="analysis-form">
      <div className="analysis-form-header">
        <h2>Select Analysis</h2>
      </div>
      <Row gutter={40}>
        <Col sm={24}>
        { analysisItems.map((item) => (
        <div className="analysis-item">
          <h2>{ item.title }</h2>
          <div className="thumb"
            style={{
              background: `url(${item.thumb || Thumb}) no-repeat center center`,
              backgroundSize: `contain`
            }}
          />
          <p>{ item.text }</p>
          <Button
            disabled={item.disabled} 
            type="primary"
            onClick={() => {
              console.log('click')
              setShowAnalysisOptions(false);
              setShowFlow(item.flow);
              // close analysis modal
              // open flow modal 
            }}
            >
              { item.button } &raquo;
            </Button>
        </div>
        ))}
        </Col>
      </Row>
    </div>
  )}
  { showFlow === 'enrichPoints' &&
   <PointForm 
    onCancel={() => onCancel()}
    setPercent={(percent) => setPercent(percent)}
    addLayer={(data) => addLayer(data)} 
   />
  }
  { showFlow === 'enrichPolygons' &&
   <PolygonForm 
     onCancel={() => onCancel()}
     setPercent={(percent) => setPercent(percent)}
     addLayer={(data) => addLayer(data)} 
    />
  }
  { showFlow === 'analyzeBuffers' &&
   <BufferForm 
    onCancel={() => onCancel()}
    setPercent={(percent) => setPercent(percent)}
    addLayer={(data) => addLayer(data)} />
  }
  </div>
  );
}

export { AnalysisForm };
