// Import necessary dependencies
import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Link,
  Routes,
  Outlet,
} from "react-router-dom";
import { MapInstance } from "./components/Map";

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<MapInstance />} />
        <Route path="/analysis/:type/:id" element={<MapInstance />} />
        {/* A catch-all route for handling 404 errors */}
        <Route path="*" element={<h2>Page not found</h2>} />
      </Routes>
    </Router>
  );
};

export default App;
