import React from 'react';
import './style.css';

export const LevelIndicator = (props) => {
    const { zoom } = props;

    const getClasses = (level) => {
        if (level === 9 && !zoom) {
            return 'active'
        }
        if (level === 9 && zoom > 0 && zoom <= 3) {
            return 'active'
        }
        if (level === 10 && zoom > 3 && zoom <= 4) {
            return 'active'
        }
        if (level === 11 && zoom > 4 && zoom <= 5) {
            return 'active'
        }
        if (level === 12 && zoom > 5 && zoom <= 8) {
            return 'active'
        }
        if (level === 13 && zoom > 8 && zoom <= 9) {
            return 'active'
        }
        if (level === 14 && zoom > 9 && zoom <= 10) {
            return 'active'
        }
        if (level === 15 && zoom > 10 && zoom <= 11) {
            return 'active'
        }
        if (level === 16 && zoom > 11 && zoom <= 13) {
            return 'active'
        }
    }

    return(
        <div className="level-indicator">
            <div className="level-indicator-title">Pixel Zoom Level</div>
            <ul>
                <li className={getClasses(16)}>16</li>
                <li className={getClasses(15)}>15</li>
                <li className={getClasses(14)}>14</li>
                <li className={getClasses(13)}>13</li>
                <li className={getClasses(12)}>12</li>
                <li className={getClasses(11)}>11</li>
                <li className={getClasses(10)}>10</li>
                <li className={getClasses(9)}>9</li>
            </ul>
        </div>
    )
}